"use client";

import Image from 'next/image';
import Link from 'next/link';

export default function Error_Page(
    {
        error,
        reset,
    }: {
        error: Error;
        reset: () => void;
    }
) {
    return (
        <>
            <h1>Error</h1>
            <p style={{color: "darkred"}}>{error.message}</p>

            <Image src="/yoda-80.png" width={80} height={85} alt="Something broke..."/>
            <p><Link href="#" onClick={reset}>Macht anwenden</Link></p>
        </>
    )
}
